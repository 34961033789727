<template>
  <div class="new_score" :class="{ is_table: curActive == 2 }">
    <div class="new_score_container">
      <!-- <div class="score_header">
				<h3><?php echo $lang['view_score'];?></h3>
				<div class="rad_box">
					<label><?php echo $lang['Toggle_view'];?></label>
					<el-radio-group v-model="curActive" @change="changView">
					<el-radio :label="1"><?php echo $lang['chart'];?></el-radio>
					<el-radio :label="2"><?php echo $lang['list'];?></el-radio>
					</el-radio-group>				
				</div>
			</div> -->
      <div class="user_info">
        <div class="photo">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
          <i v-else class="el-icon-picture-outline"></i>
        </div>
        <div class="info">
          <div class="name">
            {{ userInfo.member_truename }} <span>{{ userInfo.username }}</span>
          </div>
          <div class="info_detail">
            <div class="item">
              <p>当前班级：{{ userInfo.class_name }}</p>
              <p>班级人数：{{ userInfo.class_count }}人</p>
            </div>
            <div class="item">
              <p>账号注册时间：{{ userInfo.register_time }}</p>
              <p>
                排名统计时间：{{
                  userInfo.ranking_time ? userInfo.ranking_time : "无"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <template v-if="curActive == 1">
        <ScoreChart :userinfo="userInfo"></ScoreChart>
      </template>
      <template v-else>
        <div class="list_info">
          <!-- <span
            >用户的总分：100
            <?php echo $lang['points'];?></span
          >
          <span
            ><?php echo $lang['class_rank'];?>：
            <span v-if="tableData.class_bank == 0"
              ><?php echo $lang['No_ranking_yet'];?></span
            >
            <span v-else
              ><?php echo $lang['the'];?>{{ tableData.class_bank
              }}<?php echo $lang['place'];?></span
            >
          </span> -->
        </div>
        <el-table
          :data="tableData.operationTable"
          v-loading="loading"
          border
          :header-cell-style="{ background: '#f2f2f2' }"
          style="width: 96%; margin: 0 auto"
        >
          <el-table-column label="得分项" prop="name"></el-table-column>
          <el-table-column label="占比" prop="percent">
            <template slot-scope="scope"> {{ scope.row.percent }}% </template>
          </el-table-column>
          <el-table-column label="得分" prop="score"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showDetail(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="`${curItem.name}详情`"
          :visible.sync="detailVisible"
          width="1000px"
        >
          <!-- <div class="score">{{curItem.name}}：95</div> -->
          <template v-if="curItem.name === '流程分'">
            <el-table
              key="score"
              :data="detailList"
              show-summary
              border
              :header-cell-style="{ background: '#f2f2f2' }"
              max-height="400px"
            >
              <el-table-column
                label="任务名称"
                prop="name"
                width="200px"
              ></el-table-column>
              <el-table-column
                label="任务描述"
                prop="desc"
                class-name="align_left"
              ></el-table-column>
              <el-table-column
                label="分值"
                prop="default_score"
                width="150px"
              ></el-table-column>
              <el-table-column
                label="得分"
                prop="score"
                width="150px"
              ></el-table-column>
            </el-table>
          </template>
          <template v-else-if="curItem.name === '运营分'">
            <div class="score">运营分：{{ curscore }}</div>
            <el-table
              key="operation"
              :data="detailList"
              border
              :header-cell-style="{ background: '#f2f2f2' }"
              max-height="400px"
            >
              <el-table-column
                label="有效订单数"
                prop="valid_orders_count"
              ></el-table-column>
              <el-table-column
                label="退货/退款订单数"
                prop="return_orders_count"
              ></el-table-column>
              <el-table-column
                label="总订单数"
                prop="orders_total"
              ></el-table-column>
            </el-table>
          </template>
          <template v-else-if="curItem.name === '利润分'">
            <div class="score">利润分：{{ curscore }}</div>
            <el-table
              key="profit"
              :data="detailList"
              border
              :header-cell-style="{ background: '#f2f2f2' }"
              max-height="400px"
            >
              <el-table-column
                label="利润分"
                prop="profit_fee"
              ></el-table-column>
              <el-table-column
                label="销售额"
                prop="sales_fee"
              ></el-table-column>
              <el-table-column
                label="采购成本"
                prop="purchase_fee"
              ></el-table-column>
              <el-table-column
                label="物流成本"
                prop="order_ship_fee"
              ></el-table-column>
              <!-- <el-table-column label="<?php echo $lang['FBA'];?>" prop="amazon_ship_fee"></el-table-column> -->
              <el-table-column
                label="推广费"
                prop="adversting_fee"
              ></el-table-column>
              <el-table-column
                label="平台佣金"
                prop="platform_commission_fee"
              ></el-table-column>
              <!-- <el-table-column label="<?php echo $lang['Platform_monthly_rent'];?>" prop="platform_fee"></el-table-column> -->
            </el-table>
          </template>
          <template v-else-if="curItem.name === '教师评分'">
            <div class="score">教师评分：{{ detailList.teacher_score }}</div>
            <el-table
              :data="teacher_module_list"
              key="teacher"
              style="width: 100%"
            >
              <!-- <el-table-column prop="module_name" label="评分模块">
              </el-table-column> -->
              <el-table-column prop="score" label="教师评分">
                <template slot-scope="scope">
                  <span v-if="detailList.teacher_status == 0">教师未评</span>
                  <span v-if="detailList.teacher_status == 1">{{
                    scope.row.score
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="compnents" label="教师评语">
                <template slot-scope="scope">
                  <span v-if="detailList.teacher_status == 0">教师未评</span>
                  <span v-if="detailList.teacher_status == 1">{{
                    scope.row.compnents
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-dialog>
      </template>
    </div>
  </div>
</template>
<script>
import {
  getUserInfo,
  getUserScoreInfo,
  getUserTeacherScoreDetail,
  getUserFlowScoreDetail,
  getUserProfitScoreDetail,
  getUserOperationScoreDetail,
} from "@/api/shop.js";
import ScoreChart from "./newScoreChart.vue";
export default {
  props: {
    show_content: {
      type: Number,
      default: 1, // 1图表2列表
    },
  },
  data() {
    return {
      teacher_module_list: [],
      curActive: 1,
      loading: false,
      tableData: {},
      curItem: { name: "" },
      detailList: [],
      curscore: 0,
      detailVisible: false,
      userInfo: {},
    };
  },
  components: {
    ScoreChart,
  },
  created() {
    // this.init();
    this.getUserInfo();
  },
  methods: {
    changView() {
      if (this.curActive == 2) {
        this.init();
      }
    },
    init() {
      this.loading = true;
      getUserScoreInfo().then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        } else {
          this.$message.warning(res.msg);
        }
      });
      //   $.ajax({
      //     type: "get",
      //     url: "/index.php?act=score&op=get_user_score_info",
      //     data: {},
      //     dataType: "json",
      //     success: (res) => {
      //       this.loading = false;
      //       if (res.code == 200) {
      //         this.tableData = res.data;
      //       } else {
      //         this.$message.warning(res.msg);
      //       }
      //     },
      //     error: (err) => {
      //       this.loading = false;
      //     },
      //   });
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
        }
      });
    },
    showDetail(item) {
      this.curItem = item;
      this.detailVisible = true;
      let current_percent = parseFloat(item.percent) / 100;
      if (parseFloat(item.percent) > 0) {
        this.curscore = (parseFloat(item.score) / current_percent).toFixed(2);
      } else {
        this.curscore = 0;
      }
      this.detailList = [];
      // var API = "";
      switch (item.sign) {
        case "flow_score":
          getUserFlowScoreDetail().then((res) => {
            if (res.code == 1) {
              this.detailList = res.data;
            }
          });
          // API = "/index.php?act=score&op=get_user_flow_detail";

          break;
        case "operation_score":
          getUserOperationScoreDetail().then((res) => {
            if (res.code == 1) {
              if (Array.isArray(res.data)) {
                this.detailList = [];
              } else {
                this.detailList = [res.data];
              }
            }
          });
          // API = "/index.php?act=score&op=get_user_operation_detail";

          break;
        case "profit_score":
          getUserProfitScoreDetail().then((res) => {
            if (res.code == 1) {
              if (Array.isArray(res.data)) {
                this.detailList = [];
              } else {
                this.detailList = [res.data];
              }
            }
          });
          // API = "/index.php?act=score&op=get_user_profit_detail";
          break;
        case "teacher_points":
          getUserTeacherScoreDetail().then((res) => {
            if (res.code == 1) {
              this.detailList = res.data;
              // this.teacher_module_list = res.data.teacher_module;
              let arr = [];
              arr.push({
                score: res.data.teacher_score,
                compnents: res.data.teacher_components,
              });
               this.teacher_module_list=arr
            }
          });
          // API = "/index.php?act=score&op=get_user_teacher_detail";
          break;
        default:
          break;
      }
      // $.get(API, {}, (res) => {
      //   let re = JSON.parse(res);
      //   if (re.code == 200) {
      //     if (item.sign === "operation_score" || item.sign === "profit_score") {
      //       if (Array.isArray(re.data)) {
      //         this.detailList = [];
      //       } else {
      //         this.detailList = [re.data];
      //       }
      //     } else if (item.sign === "teacher_points") {
      //       this.detailList = re.data;
      //       this.teacher_module_list = re.data.teacher_modlue;
      //     } else {
      //       this.detailList = re.data;
      //     }
      //   } else {
      //     this.$message.warning(re.msg);
      //   }
      // });
    },
  },
  filters: {
    formatPre(val) {
      return val + "%";
    },
  },
  watch: {
    show_content(val) {
      this.curActive = val;
      if (this.curActive == 2) {
        this.init();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.new_score {
  width: 100%;
  /* background: #f0f1f5; */
  padding: 20px 0;
  box-sizing: border-box;
}
.new_score.is_table {
  background-color: #fff;
}
.new_score_container {
  width: 100%;
  margin: 0 auto;
}
.ai_chart {
  padding: 0 !important;
}
.score_header {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}
.rad_box label {
  margin-right: 8px;
}
.rad_box input[type="radio"] {
  position: absolute;
}
.new_score .user_info {
  width: 96%;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  // margin-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
}
.new_score .user_info .photo {
  width: 54px;
  height: 54px;
  background-color: #f3f3f3;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  color: #989898;
  margin-right: 20px;
}
.new_score .user_info .photo img {
  width: 100%;
  height: 100%;
}
.new_score .user_info .info {
  display: flex;
  flex-direction: column;
}
.new_score .user_info .name {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  margin-bottom: 8px;
}
.new_score .user_info .name span {
  display: inline-block;
  color: #fff;
  background-color: #f6b836;
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: -2px;
}
.new_score .user_info .info_detail {
  display: flex;
  line-height: 24px;
  font-size: 12px;
  color: #989898;
}
.new_score .user_info .info_detail .item {
  margin-right: 50px;
}
.new_score table {
  margin-bottom: 0 !important;
}
.new_score .el-table thead {
  line-height: 50px;
}
.new_score .el-table .cell {
  text-align: center;
}
.new_score .el-table td,
.el-table th {
  padding: 0;
}
.new_score .el-table .cell {
  line-height: 40px;
}
.new_score .align_left .cell {
  text-align: left !important;
}
.new_score .list_info {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  font-size: 14px;
}
.new_score .list_info span {
  flex: 1;
}
.new_score .score {
  margin-bottom: 20px;
  font-size: 14px;
}
</style>
<template id="date_btn">
    <div class="date_btn">
        <span v-for="item in dateBtns" :key="item.value" class="span_time" :class="{active: type == item.value}" @click="setOpeaTime(item.value)">{{item.name}}</span>
        <el-date-picker
            size="mini"
            @change="changeOpeateDate"
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
        </el-date-picker>        
    </div>
</template>
<script>
export default {
            prop: {
        },
        data () {
            return {
                date: [],
                type: '0',
                dateBtns: [
                    {name: "今日", value: '0'},
                    {name: "本周", value: '1'},
                    {name: "本月", value: '2'}
                ],
            }
        },
        methods: {
            setOpeaTime (val) {
                this.type = val;
                this.date = [];
                let obj = {
                    type: val,
                    data: ['', '']
                };
                this.$emit('select_date', obj);
            },
            changeOpeateDate (val) {
                this.type = '3';
                let obj = {
                    type: '3',
                    data: val
                };
                this.$emit('select_date', obj);                
            }
        }
}
</script>
<style lang="less" scoped>
    .date_btn .span_time {
	display: inline-block;
    font-family: 'PingFang SC ', 'PingFang SC';
    font-weight: 400;
    font-size: 12px;
    color: #AAAAAA;
    /* line-height: 18px; */
	padding: 0 8px;
	cursor: pointer;
}
.date_btn .span_time.active {
	color: #50ACEF;
}
</style>
<template>
  <div class="seller-body">
    <div class="seller-conter">
      <div class="seller-main">
        <div class="score_center">
          <div class="score_tab" style="margin-bottom: 20px">
            <h4>查看得分</h4>
            <div class="rad_box">
              <label style="font-size: 14px; margin-right: 10px"
                >切换查看方式：</label
              >
              <el-radio-group v-model="curActive">
                <el-radio :label="1">图表</el-radio>
                <el-radio :label="2">列表</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <template v-if="curPage === 'score'">
          <NewScore :show_content="curActive"></NewScore>
        </template>
        <template v-else>
          <ScoreRank
            :show_content="curActive"
            @set_avtive="setActive"
          ></ScoreRank>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import NewScore from "./newScore.vue";
import ScoreRank from "./scoreRank.vue";
export default {
  components: {
    // SellerHeader,
    NewScore,
    ScoreRank,
  },
  data() {
    return {
      menuType: "score", // 左侧菜单类型：goods(商品)、order(交易)、store(店铺)
      curPage: "score", // 查看得分
      userInfo: {},
      curActive: 1,
    };
  },
  created() {},
  methods: {
    setActive(val) {
      this.curActive = val;
    },
    handleClick() {
      this.curActive = 1;
    },
  },
  watch: {
    curActive(val) {
      if (val == 2) {
        document.body.style.backgroundColor = "#fff";
      } else {
        document.body.style.backgroundColor = "#f0f1f5";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.seller-conter {
  height: 100%;
}
.seller-main {
  margin-left: 0 !important;
  padding: 0;
}
.score_center {
  padding: 0 40px;
}
.score_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.score_center_wrap {
  overflow-y: auto;
}
</style>

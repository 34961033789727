<template>
  <div class="score_chart">
    <div class="defen_chart">
      <div class="rank">
        <div class="item common_box_style">
          <div
            class="img_box"
            style="display: flex; align-items: center; justify-content: center"
          >
            <i class="iconfont icon-paiming"></i>
          </div>
          <div class="info">
            <span>班级排名</span>
            <p v-if="userData.class_bank">第{{ userData.class_bank }}名</p>
            <p v-else>暂无</p>
          </div>
        </div>
        <div class="item chengji common_box_style">
          <div
            class="img_box"
            style="display: flex; align-items: center; justify-content: center"
          >
            <i class="iconfont icon-chengji-"></i>
          </div>
          <div class="info">
            <span>班级成绩</span>
            <p>{{ userData.total_score }}分</p>
          </div>
        </div>
      </div>
      <div class="defen_box common_box_style">
        <div id="defen_main" v-loading="defenLoading"></div>
        <div class="detail">
          <ul>
            <li v-for="item in scoreList" :key="item.name">
              <i class="ico"></i>
              <span class="label" @click="scrollView(item.name)">{{
                item.name
              }}</span>
              <em>|</em>
              <span class="rate">{{ item.percent }}%</span>
              <span class="get">{{ item.score }}分</span>
              <em>|</em>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="chart_title" ref="step">流程分详情</div>
    <div class="step_box common_box_style">
      <div id="step_chart" v-loading="stepLoading"></div>
    </div>
    <!-- 运营详情 -->
    <div class="chart_title" ref="opeate">运营详情</div>
    <div class="release_box common_box_style">
      <div class="number">
        <p>累计发布商品数</p>
        <span class="num">{{ opeateInfo.total_product_count }}</span>
        <p>今日上新的商品数</p>
        <span class="num">{{ opeateInfo.today_product_count }}</span>
      </div>
      <div class="release_main">
        <div class="release_top">
          <div class="title">商品发布数</div>
          <div class="time">
            <DateBtn @select_date="changeOpeateData"></DateBtn>
          </div>
        </div>
        <div id="release_chart" v-loading="releaseLoading"></div>
      </div>
    </div>
    <div class="release_box common_box_style">
      <div class="number">
        <p>累计生成订单数</p>
        <span class="num">{{ orderInfo.total_order_count }}</span>
        <p>今日新增订单数</p>
        <span class="num">{{ orderInfo.today_order_count }}</span>
      </div>
      <div class="release_main">
        <div class="release_top">
          <div class="title">生成订单数</div>
          <div class="time">
            <DateBtn @select_date="changeOrderData"></DateBtn>
          </div>
        </div>
        <div id="order_chart" v-loading="orderLoading"></div>
      </div>
    </div>
    <div class="chart_title" ref="profit">利润详情</div>
    <div class="profit_box common_box_style">
      <div class="chart_box">
        <div class="profit_top">
          <!-- <div class="title"><?php echo $lang['Profit_Details'];?></div> -->
          <div class="title">
            <div class="profit_total">
              <div class="item">
                <div class="name">销售总额</div>
                <p>
                  <span>{{ "$&nbsp;" + profitInfo.total_sales_money }}</span>
                </p>
              </div>
              <div class="item">
                <div class="name">利润总额</div>
                <p>
                  <span>{{ "$&nbsp;" + profitInfo.today_profit_money }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="time">
            <DateBtn @select_date="changeProfitData"></DateBtn>
          </div>
        </div>
        <div id="profit_chart" v-loading="profitLoading"></div>
      </div>
    </div>
    <div class="chart_title" ref="teacher">教师评分详情</div>
    <div class="teacher_box common_box_style">
      <div id="teacher_chart" v-loading="teacherLoading"></div>
    </div>
  </div>
</template>
<script>
import {
  getUserScoreDetail,
  getUserFlowDetail,
  getUserOperationProductDetail,
  getUserOperationOrderDetail,
  getUserProfitDetail,
  getUserTeacherDetail,
} from "@/api/shop.js";
import * as echarts from "echarts";
import DateBtn from "./queryDatebtn.vue";
export default {
  props: ["userinfo"],
  components: {
    DateBtn,
  },
  data() {
    return {
      userData: {},
      baseUrl: "/aliexpress/index.php?act=score_statistics&op=",
      scoreList: [], // 得分详情
      opeateQuery: {
        // 运营详情查询
        type: "0",
        start_time: "",
        end_time: "",
      },
      opeateInfo: {},
      orderQuery: {
        // 生成订单查询
        type: "0",
        start_time: "",
        end_time: "",
      },
      orderInfo: {},
      profitQuery: {
        type: "0",
        start_time: "",
        end_time: "",
      },
      profitInfo: {},
      defenLoading: false,
      stepLoading: false,
      releaseLoading: false,
      orderLoading: false,
      profitLoading: false,
      teacherLoading: false,
      colors: [
        "#facd91",
        "#81d3f8",
        "#74dfb2",
        "#ec808d",
        "#c280ff",
        "#B15D3B",
        "#70266C",
        "#18E865",
        "#3A4BD3",
        "#38D412",
      ], // 教师评分颜色
      // 得分详情
      setOption_defen: {
        title: {
          show: true,
          text: "得分详情",
          left: "center",
          top: "center",
          z: 0,
          zlevel: 100,
        },
        tooltip: {
          trigger: "item",
        },
        color: ["#81d3f8", "#74dfb2", "#facd91", "#ec808d"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["86%", "92%"],
            //环的位置
            label: {
              show: false,
              position: "inner",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "16",
              },
            },
            data: [
              {
                value: 0, //需要显示的数据
                name: "",
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}得分: </span>&nbsp;&nbsp;&nbsp;<span>${params.value}</span>分</div>`;
                  },
                },
              },
              {
                value: 0,
                //不需要显示的数据，颜色设置成和背景一样
                itemStyle: {
                  normal: { color: "#f6f6f6" },
                },
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}满分: </span>&nbsp;&nbsp;&nbsp;<span>${params.data.full_score}</span>分</div>`;
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "pie",
            radius: ["74%", "80%"],
            //环的位置
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "16",
              },
            },
            data: [
              {
                value: 0, //需要显示的数据
                name: "",
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}得分: </span>&nbsp;&nbsp;&nbsp;<span>${params.value}</span>分</div>`;
                  },
                },
              },
              {
                value: 0,
                //不需要显示的数据，颜色设置成和背景一样
                itemStyle: {
                  normal: { color: "#f6f6f6" },
                },
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}满分: </span>&nbsp;&nbsp;&nbsp;<span>${params.data.full_score}</span>分</div>`;
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "pie",
            radius: ["62%", "68%"],
            //环的位置
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "16",
              },
            },
            data: [
              {
                value: "", //需要显示的数据
                name: "",
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}得分: </span>&nbsp;&nbsp;&nbsp;<span>${params.value}</span>分</div>`;
                  },
                },
              },
              {
                value: 0,
                //不需要显示的数据，颜色设置成和背景一样
                itemStyle: {
                  normal: { color: "#f6f6f6" },
                },
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}满分: </span>&nbsp;&nbsp;&nbsp;<span>${params.data.full_score}</span>分</div>`;
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "pie",
            radius: ["50%", "56%"],
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "16",
              },
            },
            data: [
              {
                name: "",
                value: 0,
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}得分: </span>&nbsp;&nbsp;&nbsp;<span>${params.value}</span>分</div>`;
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  normal: {
                    color: "#f6f6f6",
                  },
                },
                tooltip: {
                  formatter: (params) => {
                    return `${params.data.name}<br/><div style="text-align: left"><span>${params.marker}满分: </span>&nbsp;&nbsp;&nbsp;<span>${params.data.full_score}</span>分</div>`;
                  },
                },
              },
            ],
          },
        ],
      },
      // 流程分详情
      setOption_step: {
        // title: {
        // 	show: true,
        // 	text: "<?php echo $lang['Process_details'];?>",
        // 	left: '5%',
        // },
        color: ["#dddddd", "#94daf9"],
        legend: {
          data: ["默认分值", "得分分值"],
          right: "5%",
          top: "5%",
        },
        tooltip: {},
        xAxis: {
          data: [],
          name: "任务描述",
          offset: 10,
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false },
          axisLabel: {
            show: true,
            interval: 0,
            rotate: -60,
            // formatter: function (val) {
            // 	var str = val.split('');
            // 	return str.join("\n");
            // }
          },
        },
        yAxis: {
          name: "单位：分",
        },
        grid: {
          bottom: 150,
        },
        series: [
          {
            name: "默认分值",
            type: "bar",
            data: [],
          },
          {
            barGap: "-100%",
            name: "得分分值",
            type: "bar",
            // emphasis: {
            // 	itemStyle: {
            // 		shadowBlur: 10,
            // 		shadowColor: 'rgba(0,0,0,0.3)'
            // 	}
            // },
            data: [],
          },
        ],
      },
      // 发布商品
      setOption_release: {
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        xAxis: {
          name: "时间",
          // nameLocation: 'start',
          // axisLine: {
          // 	onZero: false
          // },
          axisTick: {
            alignWithLabel: true, // 让x轴刻度不从0开始
          },
          type: "category",
          // boundaryGap: false,
          data: [],
        },
        yAxis: {
          name: "商品数",
          type: "value",
          boundaryGap: [0, "100%"],
          axisLine: {
            lineStyle: {
              // color: '#02a7f0'
            },
          },
        },
        grid: {
          left: 100,
          right: 100,
        },
        dataZoom: [
          // 配置X轴区域缩放
          {
            type: "inside",
            start: 0,
            end: 100,
            // show: false,
          },
          {
            start: 0,
            end: 100,
            show: false,
          },
        ],
        series: [
          {
            name: "发布商品数",
            type: "line",
            // symbol: 'rect',
            sampling: "lttb",
            itemStyle: {
              color: "#23b2f1",
            },
            smooth: 0.3,
            // symbol: 'circle',
            // areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //     {
            //         offset: 0,
            //         color: 'rgb(255, 158, 68)'
            //     },
            //     {
            //         offset: 1,
            //         color: 'rgb(255, 70, 131)'
            //     }
            //     ])
            // },
            data: [],
          },
        ],
      },
      // 生成订单数
      setOption_order: {
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        xAxis: {
          name: "时间",
          // nameLocation: 'start',
          axisLine: {
            lineStyle: {
              // color: '#02a7f0'
            },
          },
          axisTick: {
            alignWithLabel: true, // 让x轴刻度不从0开始
          },
          type: "category",
          // boundaryGap: false,
          data: [],
        },
        yAxis: {
          name: "订单数",
          type: "value",
          boundaryGap: [0, "100%"],
          axisLine: {
            lineStyle: {
              // color: '#02a7f0'
            },
          },
        },
        grid: {
          left: 100,
          right: 100,
        },
        dataZoom: [
          // 配置X轴区域缩放
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
            show: false,
          },
        ],
        series: [
          {
            name: "出单数量",
            type: "line",
            // symbol: 'rect',
            sampling: "lttb",
            itemStyle: {
              color: "#23b2f1",
            },
            smooth: 0.3,
            symbol: "circle",
            areaStyle: {
              color: "#d5f0fd",
            },
            data: [],
          },
        ],
      },
      // 利润详情
      setOption_profit: {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var relval = params[0].name;
            params.forEach((item) => {
              relval +=
                "<br/>" +
                item.marker +
                item.seriesName +
                ": &nbsp;" +
                item.value +
                "$";
            });
            return relval;
          },
        },
        axisLabel: {
          show: true,
          color: "#333", // 更改坐标轴文字颜色
          fontSize: 14, // 更改坐标轴文字大小
        },
        dataZoom: [
          // 配置X轴区域缩放
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
            show: false,
          },
        ],
        grid: {
          bottom: 80,
          left: 80,
          right: 80,
        },
        xAxis: {
          name: "时间",
          type: "category",
          // boundaryGap: false, // 坐标轴名字跟刻度对齐
          data: [],
          offset: 10, // x轴文字跟x轴的间距
          axisTick: {
            alignWithLabel: true, // 让x轴刻度不从0开始
          },
          axisLine: {
            lineStyle: {
              // color: '#ebebeb'
            },
          },
        },
        yAxis: {
          show: true,
          type: "value",
          name: "单位：美元",
        },
        series: [
          {
            name: "销售额",
            type: "line",
            smooth: 0.3,
            symbol: "circle",
            color: ["#2db7f5"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                },
              },
            },
            data: [],
          },
          {
            name: "利润",
            type: "line",
            smooth: 0.3,
            symbol: "circle",
            color: ["#808bc6"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                },
              },
            },
            data: [],
          },
        ],
      },
      // 教师评语
      setOption_teacher: {
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (params) => {
            return `${params[0].name}<br/>
						<div style="text-align: left"><span>${params[0].marker}评分:</span> &nbsp;&nbsp;&nbsp;<span>${params[0].value}</span>分</div>
						<div style="max-width: 200px; white-space:pre-wrap">教师评语: ${params[0].data.comments}</div>
						`;
          },
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          name: "单位：分",
          min: 0,
          max: 10,
        },
        color: ["#81d3f8"],
        series: [
          {
            type: "bar",
            name: "评分",
            barWidth: 50,
            data: [],
          },
        ],
      },
    };
  },
  created() {
    this.userData = this.userinfo;
    setTimeout(() => {
      this.getScoreDetail();
    }, 300);
    setTimeout(() => {
      this.getOperateDetail();
    }, 500);
    setTimeout(() => {
      this.getStepDetail();
    }, 700);
    setTimeout(() => {
      this.getOrderDetail();
    }, 900);
    setTimeout(() => {
      this.getProfitDetail();
    }, 1100);
    setTimeout(() => {
      this.getTeacherDetail();
    }, 1300);
  },
  mounted() {},
  methods: {
    chart_post(url, data) {
      //   return new Promise((resolve, reject) => {
      //     $.ajax({
      //       type: "post",
      //       url: this.baseUrl + url,
      //       data: data,
      //       async: true,
      //       dataType: "json",
      //       success: (res) => {
      //         resolve(res);
      //       },
      //       error: (err) => {
      //         reject(err);
      //       },
      //     });
      //   });
    },
    appendEmptyImg(name) {
      var img = new Image();
      img.src = "/amazon/templates/default/images/empty_chart.png";
      img.classList.add("empty_chart_img");
      document.getElementById(name).appendChild(img);
    },
    // 滚动
    scrollView(name) {
      switch (name) {
        case "教师评分":
          this.$refs.teacher.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
          break;
        case "流程分":
          this.$refs.step.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          break;
        case "利润分":
          this.$refs.profit.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
          break;
        case "运营分":
          this.$refs.opeate.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
          break;
        default:
          break;
      }
    },
    // 得分详情
    getScoreDetail() {
      this.defenLoading = true;
      getUserScoreDetail().then((res) => {
        if (res.code == 1) {
          this.defenLoading = false;
          this.scoreList = res.data;
          this.scoreList.forEach((item, index) => {
            this.setOption_defen.series[index].name = `得分详情`;
            this.setOption_defen.series[index].data[0].name = `${item.name}`;
            this.setOption_defen.series[index].data[0].value = item.score;
            this.setOption_defen.series[index].data[1].name = `${item.name}`;
            this.setOption_defen.series[index].data[1].value =
              item.percent - item.score;
            this.setOption_defen.series[index].data[1].full_score =
              item.percent;
          });
          let myChart = echarts.init(document.getElementById("defen_main"));
          myChart.setOption(this.setOption_defen);
        }
      });
    },
    // 流程详情
    getStepDetail() {
      this.stepLoading = true;
      getUserFlowDetail().then((res) => {
        if (res.code == 1) {
          this.stepLoading = false;
          this.setOption_step.xAxis.data = res.data.title;
          this.setOption_step.series[0].data = res.data.default_score;
          this.setOption_step.series[1].data = res.data.current_score;
          let myChart = echarts.init(document.getElementById("step_chart"));
          myChart.setOption(this.setOption_step);
        }
      });
    },
    // 运营详情
    getOperateDetail() {
      this.releaseLoading = true;
      getUserOperationProductDetail({
        ...this.opeateQuery,
      }).then((res) => {
        if (res.code == 1) {
          this.releaseLoading = false;
          this.opeateInfo = res.data;
          this.setOption_release.xAxis.data = res.data.echarts.x;
          this.setOption_release.series[0].data = res.data.echarts.y;
          if (res.data.echarts.x.length > 31) {
            // 超过31天 现在缩放控件
            this.setOption_release.dataZoom[1].show = true;
          } else {
            this.setOption_release.dataZoom[1].show = false;
          }
          let myChart = echarts.init(document.getElementById("release_chart"));
          myChart.setOption(this.setOption_release);
        }
      });
    },
    // 获取生成订单数
    getOrderDetail() {
      this.orderLoading = true;
      getUserOperationOrderDetail(this.orderQuery).then((res) => {
        if (res.code == 1) {
          this.orderLoading = false;
          this.orderInfo = res.data;
          this.setOption_order.xAxis.data = res.data.echarts.x;
          this.setOption_order.series[0].data = res.data.echarts.y;
          if (res.data.echarts.x.length > 31) {
            // 超过31天 现在缩放控件
            this.setOption_order.dataZoom[1].show = true;
          } else {
            this.setOption_order.dataZoom[1].show = false;
          }
          let myChart = echarts.init(document.getElementById("order_chart"));
          myChart.setOption(this.setOption_order);
        }
      });
    },
    // 利润详情
    getProfitDetail() {
      getUserProfitDetail(this.profitQuery).then((res) => {
        if (res.code == 1) {
          this.profitLoading = false;
          this.profitInfo = res.data;
          this.setOption_profit.xAxis.data = res.data.echarts.x;
          this.setOption_profit.series[0].data = res.data.echarts.y;
          this.setOption_profit.series[1].data = res.data.echarts.y1;
          if (res.data.echarts.x.length > 31) {
            // 超过31天 现在缩放控件
            this.setOption_profit.dataZoom[1].show = true;
          } else {
            this.setOption_profit.dataZoom[1].show = false;
          }
          let myChart = echarts.init(document.getElementById("profit_chart"));
          myChart.setOption(this.setOption_profit);
        }
      });
    },
    // 教师评分详情
    getTeacherDetail() {
      this.teacherLoading = true;
      getUserTeacherDetail().then((res) => {
        if (res.code == 1) {
          this.teacherLoading = false;
          this.setOption_teacher.xAxis.data = res.data.echarts_x;
          this.setOption_teacher.yAxis.max = res.data.echarts_max_score;
          let y = [];
          res.data.echarts_y.forEach((item, index) => {
            let it = {
              value: item,
              comments: res.data.echarts_compnents[index],
            };
            y.push(it);
          });
          this.setOption_teacher.series[0].data = y;
          let myChart = echarts.init(document.getElementById("teacher_chart"));
          myChart.setOption(this.setOption_teacher);
        }
      });
    },
    // 运营日期改变
    changeOpeateData(obj) {
      this.opeateQuery.type = obj.type;
      if (obj.data) {
        this.opeateQuery.start_time = obj.data[0];
        this.opeateQuery.end_time = obj.data[1];
        this.getOperateDetail();
      }
    },
    // 订单日期改变
    changeOrderData(obj) {
      this.orderQuery.type = obj.type;
      if (obj.data) {
        this.orderQuery.start_time = obj.data[0];
        this.orderQuery.end_time = obj.data[1];
        this.getOrderDetail();
      }
    },
    // 利润日期改变
    changeProfitData(obj) {
      this.profitQuery.type = obj.type;
      if (obj.data) {
        this.profitQuery.start_time = obj.data[0];
        this.profitQuery.end_time = obj.data[1];
        this.getProfitDetail();
      }
    },
  },
  watch: {
    userinfo(val) {
      this.userData = val;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./font/iconfont.css";
.score_chart {
  width: 100%;
}
.score_chart .defen_chart {
  display: flex;
  padding: 50px 0;
  align-items: center;
  justify-content: center;
}
.score_chart .defen_chart .rank {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 100px;
}
.score_chart .defen_chart .rank .item {
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  width: 284px;
  height: 112px;
}
.score_chart .defen_chart .rank .item:first-of-type {
  margin-bottom: 40px;
}
.score_chart .defen_chart .rank .item .img_box {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  line-height: 56px;
  text-align: center;
  background-color: #77e7a5;
  color: #fff;
  margin: 0 30px;
}
.score_chart .defen_chart .rank .item .img_box i {
  font-size: 30px;
}
.score_chart .defen_chart .rank .item .info span {
  font-size: 12px;
  color: #989898;
}
.score_chart .defen_chart .rank .item .info p {
  font-size: 28px;
  color: #666;
  font-weight: 500;
  margin-top: 12px;
}
.score_chart .chengji .img_box {
  background-color: #ff9933 !important;
}
.score_chart .chengji .info p {
  color: #77e7a5 !important;
}
.score_chart #defen_main {
  width: 400px;
  height: 320px;
}
.score_chart .defen_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.score_chart .defen_chart .detail li {
  line-height: 24px;
  font-size: 13px;
  color: #000000a5;
  margin: 10px 0;
}
.score_chart .defen_chart .detail li .ico {
  display: inline-block;
  width: 10px;
  height: 8px;
  border-radius: 4px;
  background-color: #81d3f8;
}
.score_chart .defen_chart .detail li:nth-of-type(2) .ico {
  background-color: #74dfb2;
}
.score_chart .defen_chart .detail li:nth-of-type(3) .ico {
  background-color: #facd91;
}
.score_chart .defen_chart .detail li:last-of-type .ico {
  background-color: #ec808d;
}
.score_chart .defen_chart .detail li .label {
  display: inline-block;
  width: 60px;
  text-align: right;
  cursor: pointer;
}
.score_chart .defen_chart .detail li .rate {
  color: #0000006d;
  display: inline-block;
  width: 60px;
  text-align: center;
}
.score_chart .defen_chart .detail li .get {
  display: inline-block;
  width: 60px;
  text-align: center;
}
.score_chart .defen_chart .detail li em {
  color: #ccc;
  display: inline-block;
  margin: 0 10px;
}
.score_chart #step_chart {
  width: 100%;
  height: 400px;
}
.score_chart .step_box {
  width: 100%;
  margin-bottom: 30px;
}
.score_chart .chart_title {
  font-size: 16px;
  color: #464646;
  font-weight: 700;
  margin-bottom: 30px;
  padding-left: 60px;
}
.score_chart .release_box {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.score_chart .release_box .number {
  width: 200px;
  border-right: 1px solid #ebeef5;
  padding: 30px 20px;
  text-align: center;
}
.score_chart .release_box .number p {
  font-size: 12px;
  color: #989898;
}
.score_chart .release_box .number .num {
  display: block;
  font-size: 28px;
  color: #666;
  margin-bottom: 40px;
  margin-top: 15px;
}
.score_chart .release_box .release_main {
  width: 100%;
}
.score_chart .release_box .release_top {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 100px;
}
.score_chart .release_box .release_top .title {
  font-size: 14px;
  color: #666;
}
.score_chart #release_chart,
.score_chart #order_chart,
.score_chart #profit_chart {
  width: 100%;
  height: 300px;
}
.score_chart .profit_box {
  padding: 20px 20px 0;
  margin-bottom: 30px;
}
.score_chart .profit_box .profit_total {
  display: flex;
}
.score_chart .profit_box .profit_total .item {
  padding: 0px 40px;
  text-align: center;
  font-size: 14px;
  color: #0000006d;
  line-height: 32px;
}
.score_chart .profit_box .profit_total .item span {
  color: #000000d8;
  font-size: 24px;
}
.score_chart .profit_box .profit_total .item p {
  color: #000000a5;
  font-size: 20px;
}
.score_chart .profit_box .chart_box {
  width: 100%;
}
.score_chart .profit_box .profit_top {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}
.score_chart .teacher_box {
  width: 100%;
}
.score_chart #teacher_chart {
  width: 800px;
  height: 400px;
  margin: 0 auto;
}
.score_chart .common_box_style {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  border-radius: 8px;
  background-color: #fff;
}
</style>